<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Заявки на выплату</h4>
          </div>
          <div class="d-flex align-items-center">
            <ValidationProvider
              v-slot="{ errors, reset }"
              class="w-100"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label class="search">Поиск</label>
                <md-input
                  v-model="query.search_query"
                  class="search"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>
            <md-button
              class="md-raised md-primary search-btn"
              @click="handleSort"
            >
              Поиск
            </md-button>
          </div>
        </md-card-header>
        <md-card-content>
          <PaymentTable
            :payments="payments"
            :pagination="pagination"
            :query="query"
            @handleSort="handleSort"
            @change-page="changePage"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import PaymentTable from "@/components/Tables/Payment.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    PaymentTable,
  },
  data() {
    return {
      query: {
        search_query: "",
        currentSort: "CreationDate",
        currentSortOrder: "asc",
      },
    };
  },
  computed: {
    ...mapState({
      payments: (state) => state.payments.payments,
      pagination: (state) => state.payments.pagination,
      showModal: (state) => state.modal.showModal,
    }),
  },
  watch: {
    showModal() {
      this.getPayments({
        searchQuery: this.query.search_query,
        sortBy: this.query.currentSort,
        sortOrder: this.query.currentSortOrder === "asc" ? 1 : 0,
        page: 1,
      });
    },
  },
  methods: {
    ...mapActions(["getPayments"]),
    changePage(page) {
      this.getPayments({
        searchQuery: this.query.search_query,
        sortBy: this.query.currentSort,
        sortOrder: this.query.currentSortOrder === "asc" ? 1 : 0,
        page: page,
      });
    },
    handleSort(sort = null) {
      if (sort) {
        this.query.currentSort = sort.currentSort;
        this.query.currentSortOrder = sort.currentSortOrder;
      }
      this.getPayments({
        searchQuery: this.query.search_query,
        sortBy: this.query.currentSort,
        sortOrder: this.query.currentSortOrder === "asc" ? 1 : 0,
        page: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  color: #fff !important;
  -webkit-text-fill-color: unset !important;
}
.d-flex {
  gap: 20px;
}
</style>
