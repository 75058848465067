<template>
  <div>
    <div v-if="payments" class="position-table">
      <md-table
        :value="payments"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="handleSort"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          class="cursor-pointer"
          @click="
            openModal({
              modalName: 'PaymentInfo',
              modalData: item,
            })
          "
        >
          <md-table-cell md-sort-by="CreationDate" md-label="Дата заявки"
            >{{ item.prepareDate }}
          </md-table-cell>
          <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Ник"
            >{{ item.user.userName }}
          </md-table-cell>
          <md-table-cell md-label="Валюта">
            {{
              `${item.cryptoCurrencyAmount}
             ${currencyTypes[item.cryptoCurrencyType]}`
            }}
          </md-table-cell>
          <md-table-cell md-sort-by="Status" md-label="Статус"
            >{{ statuses[item.status] }}
          </md-table-cell>
          <md-table-cell md-label="Комментарий администратора"
            >{{ item.adminComment }}
          </md-table-cell>
          <md-table-cell md-label="Скриншот отправки приза">
            <!-- <img
              class="screenshot_prooth"
              src="@/assets/img/screenshot_prooth.png"
              alt="sceenshot of prooth"
            /> -->
          </md-table-cell>
        </md-table-row>
      </md-table>
      <Pagination
        v-if="pagination"
        :current-page="pagination.page"
        :last-page="pagination.totalPages"
        @change-page="(page) => $emit('change-page', page)"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Pagination,
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
      default: null,
    },
    query: {
      type: Object,
      required: false,
      default: () => {
        return {
          currentSort: "",
        };
      },
    },
  },
  data() {
    return {
      currentSort: "CreationDate",
      currentSortOrder: "asc",
    };
  },
  computed: {
    ...mapState({
      statuses: (state) => state.statuses.statuses,
      currencyTypes: (state) => state.payments.currencyTypes,
    }),
  },
  mounted() {
    this.currentSort = this.query.currentSort;
    this.currentSortOrder = this.query.currentSortOrder;
  },
  methods: {
    ...mapMutations(["openModal"]),
    handleSort() {
      this.$emit("handleSort", {
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
.screenshot_prooth {
  width: auto;
  max-height: 100px;
}
::v-deep .md-table-sortable-icon {
  right: 8px;
  left: auto;
}
</style>
